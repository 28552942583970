import { sortBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useApiState } from '@shared/hooks/useApiState';
import { useApi } from '@shared/hooks/useApi';
import { useTenantContext } from '@shared/contexts/TenantContext/TenantContext';
import { Tenant } from '@shared/api/models/Tenant/Tenant';
import { TenantsGetByUserQuery } from '@shared/api/queries/Tenants/TenantsGetByUserQuery';
import { SelectedTenantKey } from '@shared/utils/ConnectApiUtils';
import { BlobGetSasUriQuery } from '@shared/api/queries/Blob/BlobGetSasUriQuery';
import { BlobStorageContainerType } from '@shared/api/enums/BlobStorageContainerType/BlobStorageContainerType';
import { useTranslation } from 'react-i18next';
import { Select } from '@shared/components/atoms/Select/Select';
import { OptionProps, components, SingleValueProps } from 'react-select';
import { useAnalytics } from '@shared/contexts/AnalyticsContext/AnalyticsContext';

type TOption = {
  label: string;
  value: Tenant & {
    logo: string | undefined;
  };
}

const Option = (props: OptionProps<TOption>) => {
  const { value: tenant } = props.data;

  return (
    <components.Option {...props}>
      <OptionWrapper>
        <TenantLogoWrapper>
          {tenant.logo && <TenantLogoImg loading='lazy' src={tenant.logo} />}
        </TenantLogoWrapper>
        {tenant.name}
      </OptionWrapper>
    </components.Option>
  );
};

const SingleValue = (props: SingleValueProps<TOption>) => {
  const { value: tenant } = props.data;

  return (
    <components.SingleValue  {...props} >
      <TenantLabel>Tenant:</TenantLabel> <TenantName>{tenant.name}</TenantName>
    </components.SingleValue >
  );
};

const TopBarTenantSelect = () => {
  const { t } = useTranslation(['commonApp']);
  const navigate = useNavigate();
  const { trackAction } = useAnalytics();
  const { tenant } = useTenantContext();
  const { execute } = useApi();
  const [tenantsWithLogo, setTenantsWithLogo] = useState<(Tenant & { logo: string | undefined })[]>([]);
  const { data: userTenants } = useApiState({
    query: new TenantsGetByUserQuery(),
    initialState: [],
    errorMessage: t('TopBar.Tenants.TenantsLoadError', { ns: 'commonApp' })
  }, []);

  const options: TOption[] = tenantsWithLogo.map(x => ({ label: x.name, value: x }));

  const handleSelect = (tenantId: string, tenantName: string) => {
    if (tenantId !== localStorage.getItem(SelectedTenantKey)) {
      trackAction('tenant_switch', 'tenant_navigation', { new_tenant_name: tenantName, new_tenant_id: tenantId });
      localStorage.setItem(SelectedTenantKey, tenantId);
      navigate('/');
      window.location.reload();
    }
  };

  const getBlobUri = useCallback(
    async (blobName?: string) => {
      if (!blobName) {
        return undefined;
      }

      const res = await execute({
        query: new BlobGetSasUriQuery(blobName, BlobStorageContainerType.Shared)
      });

      return res?.blobUri;
    },
    [execute]
  );

  useEffect(() => {
    const populateLogo = async () => {
      const tenantsWithLogo = await Promise.all(
        userTenants.map(async (x) => ({
          ...x,
          logo: await getBlobUri(x.operatorLogoId)
        }))
      );
      setTenantsWithLogo(sortBy(tenantsWithLogo, (x) => x.name));
    };

    populateLogo();
  }, [userTenants, getBlobUri]);

  if (userTenants.length < 2) {
    return null;
  }

  return (
    <Wrapper>
      <Select
        options={options}
        value={options.find(x => x.value.tenantId === tenant.tenantId)}
        onChange={x => x && handleSelect(x.value.tenantId, x.value.name)}
        components={{ Option, SingleValue }}
        isMulti={false}
        onMenuOpen={() => trackAction('tenant_box', 'tenant_navigation')}
      />
    </Wrapper>
  );
};

export default TopBarTenantSelect;

const Wrapper = styled.div`
  width: 160px;

  @media (min-width: 600px) {
    width: 220px;
  }
`;

const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 13px;
`;

const TenantLabel = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${p => p.theme.text.secondary};
`;

const TenantName = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.text.primary};
`;


const TenantLogoWrapper = styled.div`
  width: 40px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
`;

const TenantLogoImg = styled.img`
  width: auto;
  height: auto;
  max-width: 40px;
  max-height: 15px;
`;