import ResidentApp_CreateChallenge from '@dashboard/components/molecules/ResidentApp/Challenges/ResidentApp_CreateChallenge';
import ResidentApp_EditChallengeInfo from '@dashboard/components/molecules/ResidentApp/Challenges/ResidentApp_EditChallengeInfo';
import ResidentApp_EditImage from '@dashboard/components/molecules/ResidentApp/Challenges/ResidentApp_EditImage';
import ResidentApp_EditPrizes from '@dashboard/components/molecules/ResidentApp/Challenges/ResidentApp_EditPrizes';
import ResidentApp_ViewChallenge from '@dashboard/components/molecules/ResidentApp/Challenges/ResidentApp_ViewChallenge';
import ResidentApp from '@dashboard/components/molecules/ResidentApp/ResidentApp';
import ResidentApp_ManageUsers from '@dashboard/components/molecules/ResidentApp/Users/ResidentApp_ManageUsers';
import NestedRouteProvider from '@dashboard/providers/NestedRouteProvider';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FeatureFlag } from '@shared/api/enums/FeatureFlag/FeatureFlag';
import { Permission } from '@shared/api/enums/Permission/Permission';
import { Route } from '@shared/types/Route';

export const ResidentAppRoutes: Route[] = [
  {
    path: 'resident-app',
    element: ResidentApp,
    analytics: { page: 'resident_app', category: 'resident_app' },
    featureFlag: FeatureFlag.ResidentApp,
    permission: Permission.ViewResidentApp,
    link: {
      label: 'ResidentApp',
      path: 'resident-app',
      icon: regular('mobile-screen'),
      analytics: { action: 'resident_app', category: 'feature_navigation' },
      featureFlag: FeatureFlag.ResidentApp,
      permission: Permission.ViewResidentApp,
    },
  },
  {
    path: 'resident-app/create-challenge',
    element: ResidentApp_CreateChallenge
  },
  {
    path: 'resident-app/view-challenge/:challengeId/*',
    element: NestedRouteProvider,
    children: [
      {
        path: '*',
        element: ResidentApp_ViewChallenge
      },
      {
        path: 'edit-info',
        element: ResidentApp_EditChallengeInfo
      },
      {
        path: 'edit-prizes',
        element: ResidentApp_EditPrizes
      },
      {
        path: 'edit-image',
        element: ResidentApp_EditImage
      }
    ]
  },
  {
    path: 'resident-app/manage-users',
    element: ResidentApp_ManageUsers
  }
];