import { OperatorChallengeDto } from '@shared/api/models/ResidentApp/OperatorChallengeDto';
import { ChallengesResidentAppGetByIdQuery } from '@shared/api/queries/ResidentApp/Challenges/ChallengesResidentAppGetByIdQuery';
import { PaddedContainer } from '@shared/components/atoms/PaddedContainer/PaddedContainer';
import { Card } from '@shared/components/molecules/Card/Card';
import { useApi } from '@shared/hooks/useApi';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { CreateChallengeForm } from './ResidentApp_CreateChallenge';
import { Button } from '@shared/components/atoms/Button/Button';
import { PrizesResidentAppBulkUpdateCommand } from '@shared/api/queries/ResidentApp/Prizes/PrizesResidentAppBulkUpdateCommand';
import PrizeInfoContent from './PrizeInfoContent';
import { OperatorPrizeDto } from '@shared/api/models/ResidentApp/OperatorPrizeDto';
import { PrizesResidentAppBulkCreateCommand } from '@shared/api/queries/ResidentApp/Prizes/PrizesResidentAppBulkCreateCommand';
import TitleWithButton from '@dashboard/components/molecules/ResidentApp/Common/TitleWIthButton';
import { PrizesResidentAppDeleteCommand } from '@shared/api/queries/ResidentApp/Prizes/PrizesResidentAppDeleteCommand';
import { toast } from 'react-toastify';
import { isFuture } from 'date-fns';
import { isMetricEnabled } from '@dashboard/components/molecules/ResidentApp/ResidentAppUtils';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';
import { CustomResidentAppFeature } from '@shared/api/enums/ResidentAppFeatureType/ResidentAppFeatureType';

const ResidentApp_EditPrizes = () => {
  const { t } = useTranslation('molecules');
  const theme = useTheme();
  const { challengeId } = useParams<{ challengeId: string }>(); 
  const { state } = useLocation();
  const navigate = useNavigate();
  const { siteOperator } = useSiteContext();
  const {execute, loading, error} = useApi();
  const [challengeData, setChallengeData] = useState<OperatorChallengeDto | undefined>(state);

  const methods = useForm<CreateChallengeForm>({
    defaultValues: {
      prizes: challengeData?.prizes.map(prize => ({id: prize.id, prizeName: prize.prizeName, prizeValue: prize.prizeValue})),
    }
  })

  const handleUpdateChallenge = async (data: CreateChallengeForm) => {
    if(!challengeData) {
      return;
    }

    const prizesToUpdate: OperatorPrizeDto[] = [];
    const prizesToCreate: OperatorPrizeDto[] = [];
    
    data.prizes.forEach((prize) => {
      const isExistingPrize = challengeData.prizes.some((inputPrize) => inputPrize.id === prize.id);
      
      if (isExistingPrize) {
        prizesToUpdate.push({
          id: prize.id ?? 0,
          prizeName: prize.prizeName,
          prizeValue: prize.prizeValue ?? 0,
          challengeId: challengeData.id,
        });
      } else {
        prizesToCreate.push({
          id: 0,
          prizeName: prize.prizeName,
          prizeValue: prize.prizeValue ?? 0,
          challengeId: challengeData.id,
        });
      }
    });

    const prizesToDelete = challengeData.prizes
      .filter((prize) => !data.prizes.some((inputPrize) => inputPrize.id === prize.id))
      .map((prize) => ({
        id: prize.id,
        challengeId: challengeData.id,
      }));

    if (prizesToUpdate.length > 0) {
      await execute({
        query: new PrizesResidentAppBulkUpdateCommand(prizesToUpdate),
        successMessage: t('ChangesSaveSuccess', { ns: 'status' }),
        errorMessage: t('ChangesSaveError', { ns: 'status' }),
        pendingMessage: t('ChangesSavePending', { ns: 'status' }),
      });
    }

    if(prizesToCreate.length > 0) {
      await execute({
        query: new PrizesResidentAppBulkCreateCommand(challengeData.id, prizesToCreate),
        successMessage: t('ChangesSaveSuccess', { ns: 'status' }),
        errorMessage: t('ChangesSaveError', { ns: 'status' }),
        pendingMessage: t('ChangesSavePending', { ns: 'status' }),
      });
    }

    if (prizesToDelete.length > 0) {
      const deletionPromises = prizesToDelete.map((prize) =>
        execute({
          query: new PrizesResidentAppDeleteCommand(prize.id, challengeData.id)
        })
      );
    
      try {
        await Promise.all(deletionPromises);
        toast.success(t('PrizesDeletedSuccess', { ns: 'status' }));
      } catch (error) {
        toast.error(t('PrizesDeletedError', { ns: 'status' }));
      }
    }

    if(!error) {
      navigate('../');
    }
  };

  useEffect(() => {
    const getChallenge = async () => {
      if (!challengeId) {
        return;
      }

      const response = await execute({
        query: new ChallengesResidentAppGetByIdQuery(parseInt(challengeId)),
        errorMessage: t('ResidentApp.ChallengeLoadFailed')
      });

      setChallengeData(response);
      methods.setValue('prizes', response ? response?.prizes.map(prize => ({id: prize.id, prizeName: prize.prizeName ?? '', prizeValue: prize.prizeValue})): [])
    };

    if (!state) {
      getChallenge();
    }
  }, [challengeId, execute, methods, state, t]);

  if (siteOperator && isMetricEnabled(siteOperator, CustomResidentAppFeature.NoChallenges)) {
    return null;
  }
  
  return (
    <PaddedContainer>
      <TitleWithButton
        title={t('ResidentApp.EditPrizeInfo')}
        style={{ padding: '0 0 30px 0' }}
        backButtonUrl="../"
      />
      <EditChallengeContainer noPadding>
        <FormProvider {...methods}>
          <EditPrizeForm>
            <PrizeInfoContent
              prizes={challengeData?.prizes}
              methods={methods}
              disableDelete={challengeData ? !isFuture(new Date(challengeData.startDate)) : false}
            />
            <Buttons>
              <Button
                label={t('Cancel', { ns: 'common' })}
                onClick={() => navigate('../')}
                tertiary
                color={theme.palette.systemMessage.error}
                disabled={loading}
              />
              <Button
                label={t('Update', { ns: 'common' })}
                onClick={methods.handleSubmit((data) => handleUpdateChallenge(data))}
                disabled={loading}
              />
            </Buttons>
          </EditPrizeForm>
        </FormProvider>
      </EditChallengeContainer>
    </PaddedContainer>
  );
};

export default ResidentApp_EditPrizes;

const EditChallengeContainer = styled(Card)`
  padding: 25px 20px;
  margin-top: 30px;
`;

const EditPrizeForm = styled.form``;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;