import { PaddedContainer } from '@shared/components/atoms/PaddedContainer/PaddedContainer';
import { useTranslation } from 'react-i18next';
import styled, { CSSProperties, useTheme } from 'styled-components';
import SiteOperatorInfo from './SiteOperatorInfo/SiteOperatorInfo';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';
import SiteOperatorEditModal from './SiteOperatorInfo/SiteOperatorEditModal';
import { Button } from '@shared/components/atoms/Button/Button';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { TooltipPlacement } from '@shared/components/atoms/Tooltip/Tooltip.types';
import ChallengeOverviewTable from './Challenges/ChallengeOverviewTable';
import { useNavigate } from 'react-router-dom';
import ResidentAppPreview from './ResidentAppPreview';
import TitleWithButton from '@dashboard/components/molecules/ResidentApp/Common/TitleWIthButton';
import IconWithText from '@dashboard/components/molecules/ResidentApp/Common/IconWithText';
import { useUserContext } from '@shared/contexts/UserContext/UserContext';
import { useModal } from '@shared/hooks/useModal';
import PushNotificationsModal from './Notifications/PushNotificationsModal';
import { isMetricEnabled } from './ResidentAppUtils';
import Label from './Common/Label';
import { CustomResidentAppFeature } from '@shared/api/enums/ResidentAppFeatureType/ResidentAppFeatureType';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { Card } from '@shared/components/molecules/Card/Card';
import TemperatureThresholdForm from './Thresholds/TemperatureThresholdForm';
import ElectricityThresholdForm from './Thresholds/ElectricityThresholdForm';

const ResidentApp = () => {
  const { t } = useTranslation('molecules');
  const theme = useTheme();
  const navigate = useNavigate();
  const { siteOperator} = useSiteContext();
  const { isSuperAdmin } = useUserContext();
  const {isOpen: isEditModalOpen, setIsOpen: setIsEditModalOpen} = useModal({disableCloseOnClickOutside: true});
  const {isOpen: isPushNotificationModalOpen, setIsOpen: setIsPushNotificationModalOpen} = useModal({disableCloseOnClickOutside: true});

  const getTooltipOptions = (label: string, style: CSSProperties) => ({
    content: label,
    placement: TooltipPlacement.Bottom,
    fontSize: '14px',
    styles: {...style, maxWidth: 215},
    tooltipColor: theme.palette.tooltip.background.primary,
    hideTriangle: false
  });

  if (!siteOperator) {
    return <ResidentAppPreview />;
  }

  return (
    <>
      <PaddedContainer>
        <TitleWithButton
          title={t('ResidentApp.ResidentApp', { ns: 'molecules' })}
          button={
            <ButtonContainer>
              {isSuperAdmin && (
                <Button
                  label={
                    <IconWithText
                      icon={regular('bell-on')}
                      text={t('ResidentApp.SendAPushNotification')}
                    />
                  }
                  onClick={() => setIsPushNotificationModalOpen(true)}
                  secondary
                />
              )}
              <Button
                label={<IconWithText icon={regular('users')} text={t('ResidentApp.ManageUsers')} />}
                onClick={() => navigate('./manage-users')}
                style={{ marginRight: '-16px' }}
                secondary
                disabled={!siteOperator.email}
                tooltip={
                  !siteOperator
                    ? getTooltipOptions(t('ResidentApp.ManageUsersDisabledTooltip'), {
                      padding: 10,
                      marginRight: -25,
                    })
                    : undefined
                }
              />
            </ButtonContainer>
          }
        />
        <TitleWithButton
          title={t('ResidentApp.GeneralInformation')}
          titleSize="md"
          button={
            <Button
              label={<IconWithText icon={solid('pencil')} text={t('Edit', { ns: 'common' })} />}
              tertiary
              onClick={() => setIsEditModalOpen(true)}
            />
          }
          style={{ padding: '50px 0 3px' }}
        />
        <Label text={t('ResidentApp.GeneralInformationDescription')} style={{ marginBottom: 20 }} />
        <SiteOperatorInfo siteOperator={siteOperator} />

        {/* show site thresholds for temperature if enabled */}
        {isMetricEnabled(siteOperator, MetricType.Temperature) && siteOperator.siteThresholds && (
          <>
            <TitleWithButton
              title={t('ResidentApp.Thresholds')}
              titleSize="md"
              style={{ padding: '35px 0 3px' }}
            />
            <Label text={t('ResidentApp.ThresholdsDescription')} style={{ marginBottom: 20 }} />
            <Label text={t('ResidentApp.ThresholdDescription2')} style={{ marginBottom: 20 }} />
            <Card>
              <TemperatureThresholdForm
                siteThresholds={siteOperator.siteThresholds}
              />
            </Card>
          </>
        )}

        {/* show space type thresholds for electricity if enabled */}
        {isMetricEnabled(siteOperator, MetricType.ElectricityKwh) && siteOperator.spaceTypeThresholds && (
          <>
            {!isMetricEnabled(siteOperator, MetricType.Temperature) && (
              <>
                <TitleWithButton
                  title={t('ResidentApp.Thresholds')}
                  titleSize="md"
                  style={{ padding: '35px 0 3px' }}
                />
                <Label text={t('ResidentApp.ThresholdsDescription')} style={{ marginBottom: 20 }} />
                <Label text={t('ResidentApp.ThresholdDescription2')} style={{ marginBottom: 20 }} />
              </>
            )}

            <Card>
              <ElectricityThresholdForm spaceTypeThresholds={siteOperator.spaceTypeThresholds}/>
            </Card>
          </>
        )}

        {/* hide challenge section if NoChallenges toggled on */}
        {!isMetricEnabled(siteOperator, CustomResidentAppFeature.NoChallenges) && (
          <>
            <TitleWithButton
              title={t('ResidentApp.Challenges')}
              titleSize="md"
              style={{ padding: '35px 0 20px' }}
              button={
                <Button
                  label={
                    <IconWithText icon={solid('plus')} text={t('ResidentApp.CreateChallenge')} />
                  }
                  disabled={!siteOperator.email}
                  onClick={() => navigate('./create-challenge')}
                  tooltip={
                    !siteOperator
                      ? getTooltipOptions(t('ResidentApp.CreateChallengeDisabledTooltip'), {
                        padding: 10,
                      })
                      : undefined
                  }
                />
              }
            />
            <ChallengeOverviewTable hasSiteOperator={!!siteOperator.email} />
          </>
        )}
      </PaddedContainer>

      <SiteOperatorEditModal
        showModal={isEditModalOpen}
        onCloseModal={() => setIsEditModalOpen(false)}
      />
      <PushNotificationsModal
        showModal={isPushNotificationModalOpen}
        onCloseModal={() => setIsPushNotificationModalOpen(false)}
      />
    </>
  );
};

export default ResidentApp;

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-right: 16px;
`;