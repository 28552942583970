import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import EnergyMeterListBulkAction from './EnergyMeterListBulkAction';
import { useTranslation } from 'react-i18next';
import EnergyMetersGetByBuildingIdQuery from '@settings/api/queries/EnergyMeters/EnergyMetersGetByBuildingIdQuery';
import { EnergyMeterCategory } from '@shared/api/enums/EnergyMeterCategory/EnergyMeterCategory';
import { EnergyMeterTopologyLevel } from '@shared/api/enums/EnergyMeterTopologyLevel/EnergyMeterTopologyLevel';
import { EnergyMeterType } from '@shared/api/enums/EnergyMeterType/EnergyMeterType';
import { Building } from '@shared/api/models/Building/Building';
import { EnergyMeterTopologyDto } from '@shared/api/models/EnergyMeter/EnergyMeterTopologyDto';
import { SearchField } from '@shared/components/atoms/SearchField/SearchField';
import { ITableColumn } from '@shared/components/molecules/Table/Table.types';
import { TopBarHeight, HorizontalNavbarHeight } from '@shared/theme/designConstants';
import { useDeviceConfigContext } from '@shared/contexts/DeviceConfigContext/DeviceConfigContext';
import { useApiState } from '@shared/hooks/useApiState';
import { includesCI } from '@shared/utils/StringUtils';
import { Select } from '@shared/components/atoms/Select/Select';
import { Table } from '@shared/components/molecules/Table/Table';

interface IEnergyMeterList {
  building: Building;
}

const EnergyMeterList = ({ building }: IEnergyMeterList) => {
  const { t } = useTranslation(['settingsAsset']);
  const { getDisplayString } = useDeviceConfigContext();
  const [searchString, setSearchString] = useState<string>('');
  const [selectedCategory, setSelectedCategory] = useState<EnergyMeterCategory>();
  const [selectedTopology, setSelectedTopology] = useState<EnergyMeterTopologyLevel | null>();
  const [selectedMeterType, setSelectedMeterType] = useState<EnergyMeterType>();
  const [selectedMeters, setSelectedMeters] = useState<EnergyMeterTopologyDto[]>([]);
  const [displayCount, setDisplayCount] = useState<number>();

  const tableColumns: ITableColumn<EnergyMeterTopologyDto>[] = useMemo(() => ([
    {
      label: t('Buildings.EnergyMeters.EnergyMeterList.DeviceName', { ns: 'settingsAsset' }),
      key: 'deviceFriendlyName',
    },
    {
      label: t('Buildings.EnergyMeters.EnergyMeterList.MeterNumber', { ns: 'settingsAsset' }),
      key: 'meterNumber',
    },
    {
      label: t('Buildings.EnergyMeters.EnergyMeterList.DeviceModel', { ns: 'settingsAsset' }),
      key: 'deviceModel',
      displayFormat: record => getDisplayString(record.deviceModel),
    },
    {
      label: t('Buildings.EnergyMeters.EnergyMeterList.Space', { ns: 'settingsAsset' }),
      key: 'spaceName',
    },
    {
      label: t('Buildings.EnergyMeters.EnergyMeterList.MeterType', { ns: 'settingsAsset' }),
      key: 'meterType',
    },
    {
      label: t('Buildings.EnergyMeters.EnergyMeterList.TopologyCategory', { ns: 'settingsAsset' }),
      key: 'meterTopologyLevel',
      displayFormat: record => `${record.meterTopologyLevel ?? ''}${record.meterCategory ? ` (${record.meterCategory})` : ''}`
    }
  ]), [getDisplayString, t]);

  const { data: meters, execute: refreshMeters, loading } = useApiState({
    query: new EnergyMetersGetByBuildingIdQuery(building.id),
    initialState: [],
  }, [building]);

  let categoryOptions: { label: string, value: EnergyMeterCategory | undefined }[] = Object.values(EnergyMeterCategory).map(x => ({ label: x.toString(), value: x }));
  categoryOptions = [{ label: t('Buildings.EnergyMeters.EnergyMeterList.All', { ns: 'settingsAsset' }), value: undefined }, ...categoryOptions];

  let topologyLevelOptions: { label: string, value: EnergyMeterTopologyLevel | undefined | null }[] = Object.values(EnergyMeterTopologyLevel).map(x => ({ label: x.toString(), value: x }));
  topologyLevelOptions = [
    { label: t('Buildings.EnergyMeters.EnergyMeterList.All', { ns: 'settingsAsset' }), value: undefined },
    ...topologyLevelOptions,
    { label: t('Buildings.EnergyMeters.EnergyMeterList.Unset', { ns: 'settingsAsset' }), value: null },
  ];

  const meterTypeOptions = [
    { label: t('Buildings.EnergyMeters.EnergyMeterList.All', { ns: 'settingsAsset' }), value: undefined },
    { label: EnergyMeterType.Electricity, value: EnergyMeterType.Electricity },
    { label: EnergyMeterType.Gas, value: EnergyMeterType.Gas },
    { label: EnergyMeterType.Heating, value: EnergyMeterType.Heating },
    { label: EnergyMeterType.Water, value: EnergyMeterType.Water },
  ];

  const topologyFilter = useCallback((x: EnergyMeterTopologyDto) => {
    return selectedTopology === undefined ? true : x.meterTopologyLevel === selectedTopology;
  }, [selectedTopology]);

  const categoryFilter = useCallback((x: EnergyMeterTopologyDto) => {
    return selectedCategory ? x.meterCategory === selectedCategory : true;
  }, [selectedCategory]);

  const meterTypeFilter = useCallback((x: EnergyMeterTopologyDto) => {
    return selectedMeterType ? x.meterType === selectedMeterType : true;
  }, [selectedMeterType]);

  const searchFilter = useCallback((x: EnergyMeterTopologyDto) => {
    return includesCI(x.deviceFriendlyName, searchString)
      || includesCI(x.meterNumber, searchString)
      || includesCI(getDisplayString(x.deviceModel), searchString)
      || includesCI(x.spaceName, searchString)
      || includesCI(x.meterType, searchString)
      || (x.meterTopologyLevel && includesCI(x.meterTopologyLevel, searchString))
      || (x.meterCategory && includesCI(x.meterCategory, searchString))
  }, [searchString, getDisplayString]);

  const handleTopologyFilterChange = (value: EnergyMeterTopologyLevel | undefined | null) => {
    setSelectedTopology(value);

    if (value === EnergyMeterTopologyLevel.Main || value === EnergyMeterTopologyLevel.Distribution) {
      setSelectedCategory(undefined);
    }
  };

  return (
    <>
      <FlexRow style={{ marginBottom: '15px' }}>
        <Heading>
          {t('Buildings.EnergyMeters.EnergyMeterList.EnergyMeters', { ns: 'settingsAsset' })} <ThemeColored>({meters.length})</ThemeColored>
        </Heading>

        <FilterIconWrapper>
          <FilterIcon icon={regular('filter')} />
          {!!displayCount && displayCount < meters.length &&
            <FilteredCount>({displayCount})</FilteredCount>
          }
        </FilterIconWrapper>

        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <Label>
            {t('Buildings.EnergyMeters.EnergyMeterList.Topology', { ns: 'settingsAsset' })}:
          </Label>
          <div style={{ width: '150px' }}>
            <Select
              isSearchable={true}
              options={topologyLevelOptions}
              value={topologyLevelOptions.find(x => x.value === selectedTopology) ?? undefined}
              onChange={x => handleTopologyFilterChange(x?.value)}
            />
          </div>
        </div>

        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <Label>
            {t('Buildings.EnergyMeters.EnergyMeterList.Category', { ns: 'settingsAsset' })}:
          </Label>
          <div style={{ width: '150px' }}>
            <Select
              isSearchable={true}
              options={categoryOptions}
              value={categoryOptions.find(x => x.value === selectedCategory) ?? undefined}
              onChange={x => setSelectedCategory(x?.value)}
              isDisabled={selectedTopology !== undefined && selectedTopology !== EnergyMeterTopologyLevel.Sub}
            />
          </div>
        </div>

        <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <Label>
            {t('Buildings.EnergyMeters.EnergyMeterList.MeterType', { ns: 'settingsAsset' })}:
          </Label>
          <div style={{ width: '150px' }}>
            <Select
              isSearchable={true}
              options={meterTypeOptions}
              value={meterTypeOptions.find(x => x.value === selectedMeterType) ?? undefined}
              onChange={x => setSelectedMeterType(x?.value)}
            />
          </div>
        </div>

        <SearchField
          placeholder={t('Buildings.EnergyMeters.EnergyMeterList.SearchMeters', { ns: 'settingsAsset' })}
          onSearchChange={setSearchString}
          width='240px'
        />
      </FlexRow>

      <Table
        columns={tableColumns}
        records={meters}
        recordKey={'id'}
        defaultSortColumn="meterNumber"
        emptyMessage={t('Buildings.EnergyMeters.EnergyMeterList.EmptyMeters', { ns: 'settingsAsset' })}
        selectable
        loading={loading}
        onSelect={setSelectedMeters}
        onRecordCountChange={setDisplayCount}
        cardEffect
        fullHeightSubtractor={TopBarHeight + HorizontalNavbarHeight + 220}
        highlightString={searchString}
        filters={[topologyFilter, categoryFilter, meterTypeFilter, searchFilter]}
        bulkAction={
          <EnergyMeterListBulkAction
            selectedMeters={selectedMeters}
            allMeters={meters}
            refreshMeters={refreshMeters}
          />
        }
      />
    </>
  );
};

export default EnergyMeterList;

const FlexRow = styled.div` 
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
`;

const Heading = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
`;

const ThemeColored = styled.span`
  color: ${p => p.theme.palette.primary};
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: 400;
  color: ${p => p.theme.palette.forms.label.color};
`;

const FilterIconWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const FilterIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  color: ${p => p.theme.palette.primary};
`;

const FilteredCount = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.palette.primary};
`;