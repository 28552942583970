import { FeatureFlag } from '@shared/api/enums/FeatureFlag/FeatureFlag';
import { Site } from '@shared/api/models/Site/Site';
import { Title } from '@shared/components/atoms/Title/Title';
import { useTenantContext } from '@shared/contexts/TenantContext/TenantContext';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ClimateControlFeature from './Features/ClimateControlFeature';
import FairUseFeature from './Features/FairUseFeature';
import ResidentAppFeature from './Features/ResidentAppFeature';
import { useApiState } from '@shared/hooks/useApiState';
import SiteFeaturesGetAllQuery from '@shared/api/queries/SiteFeatures/SiteFeaturesGetAllQuery';
import SiteFeatureGeneric from './SiteFeatureGeneric';

interface ISiteSettingsFeatures {
  site: Site;
}

const SiteSettings_Features = ({ site }: ISiteSettingsFeatures) => {
  const { t } = useTranslation();
  const { hasFeatureFlag } = useTenantContext();
  const { data: features } = useApiState({
    query: new SiteFeaturesGetAllQuery(),
    initialState: []
  }, []);

  return (
    <Wrapper>
      <Title
        text={t('Sites.SiteEditing.Features', { ns: 'settingsAsset' })}
        style={{ paddingBottom: '20px' }}
      />
      <ClimateControlFeature site={site} />
      <FairUseFeature site={site} />

      {hasFeatureFlag(FeatureFlag.ResidentApp) &&
        <ResidentAppFeature site={site} />
      }

      {features.map(feature => (
        <SiteFeatureGeneric
          key={feature.featureFlag}
          site={site}
          feature={feature}
        />
      ))}
    </Wrapper>
  );
};

export default SiteSettings_Features;

const Wrapper = styled.div`
  width: 650px;
  margin: 40px auto;
`;