import EnergyMeterBillGetByIdQuery from '@settings/api/queries/EnergyMeters/UtilityBills/EnergyMeterBillGetByIdQuery';
import { EnergyMeterType } from '@shared/api/enums/EnergyMeterType/EnergyMeterType';
import { PaddedContainer } from '@shared/components/atoms/PaddedContainer/PaddedContainer';
import { Card } from '@shared/components/molecules/Card/Card';
import { useApiState } from '@shared/hooks/useApiState';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import BillingMeterUtilityTabs, { utilityTabs } from './BillingMeterUtilityTabs';
import { EnergyMeterBillFormValues } from './UtilityConsumptionCreate'
import BillingMeterForm from './BillingMeterForm';
import { useModal } from '@shared/hooks/useModal';
import { WarningDialog } from '@shared/components/molecules/WarningDialog/WarningDialog';
import { switchUtilityTab } from './BillingMeterUtils';
import { BackButton } from '@shared/components/atoms/BackButton/BackButton';

const UtilityConsumptionEdit = () => {
  const { billId } = useParams<{ billId: string }>();

  const { data: energyMeterBill } = useApiState({
    query: billId === undefined ? undefined : new EnergyMeterBillGetByIdQuery(parseInt(billId))
  }, [billId]);

  const [selectedEdit, setSelectedEdit] = useState<number>(0);
  const [selectedUtilityEdit, setSelectedUtilityEdit] = useState<EnergyMeterType>(EnergyMeterType.Electricity);
  const [nextSelectedEdit, setNextSelectedEdit] = useState<number>(0);
  const [nextSelectedUtilityEdit, setNextSelectedUtilityEdit] = useState<EnergyMeterType>(EnergyMeterType.Electricity);
  const { t } = useTranslation();
  const theme = useTheme();
  const lineRef = useRef<HTMLDivElement>(null);
  const tabRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});
  const formMethods = useForm<EnergyMeterBillFormValues>();
  const { reset } = formMethods;
  const [hasConsumption, setHasConsumption] = useState(false);
  const [hasMeterReading, setHasMeterReading] = useState(false);
  const [hasMeterNumber, setHasMeterNumber] = useState(false);

  const { isOpen, toggle, ref } = useModal({});

  useEffect(() => {
    if (energyMeterBill) {
      const utility = energyMeterBill.meterType;
      const index = utilityTabs.findIndex(x => x.utilityType === energyMeterBill.meterType)
      setSelectedEdit(index);
      setSelectedUtilityEdit(utility);
    }
  }, [energyMeterBill]);

  useEffect(() => {
    switchUtilityTab(selectedEdit, tabRefs, lineRef)
  }, [selectedEdit]);

  const handleTabClick = (utility: EnergyMeterType, currentIndex: number) => {
    setSelectedEdit(currentIndex);
    setSelectedUtilityEdit(utility);
  };

  const handleUtilityDialogWarningToggle = (nextSelectedUtility: EnergyMeterType, nextIndex: number) => {
    setNextSelectedEdit(nextIndex);
    setNextSelectedUtilityEdit(nextSelectedUtility)

    toggle();
  }

  const handleUtilityWarningDialogConfirm = () => {
    reset({
      meterNumber: '',
      location: '',
      meterReading: '',
      consumption: '',
      periodStartDate: undefined,
      periodEndDate: undefined,
      measuredOn: undefined
    })
    setHasConsumption(false);
    setHasMeterReading(false);
    setSelectedEdit(nextSelectedEdit);
    setSelectedUtilityEdit(nextSelectedUtilityEdit);
    toggle();
  }

  return (
    <>
      <WarningDialog
        modalRef={ref}
        isOpen={isOpen}
        sectionOne={t('UtilityConsumption.TabSwitchConfirmationMessage', { ns: 'molecules' })}
        sectionTwo={t('UtilityConsumption.TabSwitchConfirmationSubTextEdit', { ns: 'molecules' })}
        confirmButton={t('Leave', { ns: 'common' })}
        confirmButtonColor={theme.palette.primary}
        cancelButton={t('Stay', { ns: 'common' })}
        onCancel={toggle}
        onConfirm={() => { handleUtilityWarningDialogConfirm() }}
      />

      <PaddedContainer centered>
        <Container>
          <BackButton
            label={t('BackToBills', { ns: 'navigation' })}
            url='./..'
          />

          <Card
            cardTitle={t('UtilityConsumption.EditBill', { ns: 'molecules' })}
            noPadding
          >
            {selectedEdit != undefined &&
              <BillingMeterUtilityTabs
                tabRefs={tabRefs}
                selected={selectedEdit}
                onTabClick={handleTabClick}
                lineRef={lineRef}
                hasMeterNumber={hasMeterNumber}
                hasMeterReading={hasMeterReading}
                hasConsumption={hasConsumption}
                onUtilityDialogWarningToggle={handleUtilityDialogWarningToggle}
              />
            }
            {energyMeterBill && selectedUtilityEdit &&
              <FormProvider {...formMethods}>
                <BillingMeterForm
                  energyMeterBill={energyMeterBill}
                  selectedUtility={selectedUtilityEdit}
                  setSelected={setSelectedEdit}
                  setSelectedUtility={setSelectedUtilityEdit}
                  setNextSelected={setNextSelectedEdit}
                  setNextSelectedUtility={setNextSelectedUtilityEdit}
                  setHasMeterReading={setHasMeterReading}
                  setHasConsumption={setHasConsumption}
                  hasMeterReading={hasMeterReading}
                  hasConsumption={hasConsumption}
                  setHasMeterNumber={setHasMeterNumber}
                />
              </FormProvider>
            }
          </Card>
        </Container>
      </PaddedContainer>
    </>
  );
}

export default UtilityConsumptionEdit;

const Container = styled.div` 
  width: 100%;
  max-width: 520px;
`;