import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { OperatorChallengeDto } from '@shared/api/models/ResidentApp/OperatorChallengeDto';
import { Button } from '@shared/components/atoms/Button/Button';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import InputFormField from '@dashboard/components/molecules/ResidentApp/Common/InputFormField';
import LabelWithCharacterCount from '@dashboard/components/molecules/ResidentApp/Common/LabelWithCharacterCount';
import { TextArea } from '@shared/components/atoms/Form/Form';
import { CreateChallengeForm } from './ResidentApp_CreateChallenge';
import ResidentAppFormErrorMessage from '@dashboard/components/molecules/ResidentApp/Common/ResidentAppFormErrorMessage';
import { getDisplayChallengeStatus, isEndDateAfterStartDate } from '@dashboard/components/molecules/ResidentApp/ResidentAppUtils';
import { format } from 'date-fns';
import TitleWithButton from '@dashboard/components/molecules/ResidentApp/Common/TitleWIthButton';
import IconWithText from '@dashboard/components/molecules/ResidentApp/Common/IconWithText';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import en from 'antd/es/date-picker/locale/en_US';
import Label from '@dashboard/components/molecules/ResidentApp/Common/Label';

type ChallengeInfoContentProps = {
  challenge?: OperatorChallengeDto;
  onEdit?: () => void;
  isEditDisabled?: boolean;
  methods?: UseFormReturn<CreateChallengeForm>;
}

const ChallengeInfoContent = ({challenge, onEdit, isEditDisabled, methods}: ChallengeInfoContentProps) => {
  const { t } = useTranslation('molecules');
  const theme = useTheme();
  const { toLocale, getUnit, localisation } = useLocalisation();

  const modalTemperature = `${toLocale(MetricType.Temperature, 22)}${getUnit(MetricType.Temperature)}`;

  const TitleModalContent = (
    <>
      <ModalTitle>{t('ResidentApp.TitleHelp')}</ModalTitle>
      <ModalDescription>{t('ResidentApp.TitleHelpSummary')}</ModalDescription>
      <List>
        <ListItem>
          {t('ResidentApp.TitleHelpList.ItemOne', {
            temperature: modalTemperature,
            currency: localisation.currency,
          })}
        </ListItem>
        <ListItem>
          {t('ResidentApp.TitleHelpList.ItemTwo', {
            temperature: modalTemperature,
            currency: localisation.currency,
          })}
        </ListItem>
        <ListItem>
          {t('ResidentApp.TitleHelpList.ItemThree', {
            temperature: modalTemperature,
          })}
        </ListItem>
      </List>
    </>
  );

  const DescriptionModalContent = (
    <>
      <ModalTitle>{t('ResidentApp.DescriptionHelp')}</ModalTitle>
      <ModalDescription>{t('ResidentApp.DescriptionHelpSummary')}</ModalDescription>
      <List>
        <ListItem>
          {t('ResidentApp.DescriptionHelpList.ItemOne', {
            temperature: modalTemperature,
            currency: localisation.currency,
          })}
        </ListItem>
      </List>
    </>
  );

  return (
    <div>
      <TitleWithButton 
        title={t('ResidentApp.ChallengeInfo')} 
        button={challenge && onEdit && (
          <Button
            label={<IconWithText icon={regular('pencil')} color={theme.palette.text.fair} text={t('Edit', {ns: 'common'})}/>}
            tertiary
            onClick={onEdit}
            disabled={isEditDisabled}
          />
        )}
      />

      <Content>
        {challenge && onEdit && (
          <div>
            <Label text={t('ResidentApp.Status')} marginBottom />
            <Text>{t(getDisplayChallengeStatus(challenge))}</Text>
          </div>
        )}
        <div>
          <DateInputs>
            <DateWrapper>
              <Label inputName="startDate" text={t('ResidentApp.StartDate')} marginBottom />
              {methods ? (
                <>
                  <Controller
                    name="startDate"
                    control={methods.control}
                    rules={{
                      required: {
                        value: true,
                        message: t('StartDateRequired', { ns: 'validation' }),
                      },
                    }}
                    render={({ field }) => (
                      <StyledDatepicker
                        {...field}
                        locale={{lang: {...en.lang, fieldDateFormat: localisation.dateFormats.datepickerField}, timePickerLocale: {...en.timePickerLocale}}}
                        minDate={dayjs(new Date())}
                        placeholder={localisation.dateFormats.datepickerField.toLowerCase()}
                      />
                    )}
                  />
                  {methods.formState.errors.startDate && (
                    <ResidentAppFormErrorMessage
                      message={methods.formState.errors.startDate.message}
                    />
                  )}
                </>
              ) : (
                <Text>{challenge && format(new Date(challenge.startDate), localisation.dateFormats.slashFormatDisplay)}</Text>
              )}
            </DateWrapper>
            <DateWrapper>
              <Label inputName="endDate" text={t('ResidentApp.EndDate')} marginBottom />
              {methods ? (
                <>
                  <Controller
                    name="endDate"
                    control={methods.control}
                    rules={{
                      required: {
                        value: true,
                        message: t('EndDateRequired', { ns: 'validation' }),
                      },
                    }}
                    render={({ field }) => (
                      <StyledDatepicker
                        {...field}
                        locale={{lang: {...en.lang, fieldDateFormat: localisation.dateFormats.datepickerField}, timePickerLocale: {...en.timePickerLocale}}}
                        minDate={dayjs(new Date())}
                        placeholder={localisation.dateFormats.datepickerField.toLowerCase()}
                      />
                    )}
                  />
                  {methods.formState.errors.endDate && (
                    <ResidentAppFormErrorMessage
                      message={methods.formState.errors.endDate.message}
                    />
                  )}
                </>
              ) : (
                <Text>{challenge && format(new Date(challenge.endDate), localisation.dateFormats.slashFormatDisplay)}</Text>
              )}
            </DateWrapper>
          </DateInputs>
          {methods &&
            !isEndDateAfterStartDate(methods.watch('startDate'), methods.watch('endDate')) &&
            methods.formState.isDirty && (
            <ResidentAppFormErrorMessage message={t('StartDateBeforeEndDate', {ns: 'validation'})} />
          )}
        </div>

        <div>
          {methods ? (
            <>
              <InputFormField
                inputName="averageTemperature"
                value={challenge?.targetValue}
                inputType="number"
                label={t('ResidentApp.AverageTemperatureUnit', {temperatureUnit: getUnit(MetricType.Temperature)})}
                isRequired={true}
                min={'0'}
              />
              {methods.formState.errors.averageTemperature && (
                <ResidentAppFormErrorMessage
                  message={methods.formState.errors.averageTemperature.message}
                />
              )}
            </>
          ) : (
            <>
              <Label text={t('ResidentApp.AverageTemperatureUnit', {temperatureUnit: getUnit(MetricType.Temperature)})} marginBottom />
              <Text>{toLocale(MetricType.Temperature, challenge?.targetValue ?? 0, {round: 1})}</Text>
            </>
          )}

          <AverageTemperatureInfo>{t('ResidentApp.AverageTemperatureInfo')}</AverageTemperatureInfo>
        </div>

        <div>
          {methods ? (
            <>
              <LabelWithCharacterCount
                inputName="title"
                label={t('ResidentApp.Title')}
                modalContent={TitleModalContent}
                currentCharacterCount={methods.watch('title')?.length ?? 0}
                characterLimit={100}
              />
              <InputFormField
                inputName="title"
                value={challenge?.title}
                placeholder={t('ResidentApp.ChallengeTitleInputPlaceholder')}
                maxLength={100}
                isRequired={true}
                requiredErrorMessage={t('TitleRequired', { ns: 'validation' })}
              />
              {methods.formState.errors.title && (
                <ResidentAppFormErrorMessage message={methods.formState.errors.title.message} />
              )}
            </>
          ) : (
            <>
              <Label text={t('ResidentApp.Title')} marginBottom />
              <Text>{challenge?.title}</Text>
            </>
          )}
        </div>

        <div>
          {methods ? (
            <>
              <LabelWithCharacterCount
                inputName="challengeDescription"
                label={t('ResidentApp.ChallengeDescription')}
                modalContent={DescriptionModalContent}
                currentCharacterCount={methods.watch('challengeDescription')?.length ?? 0}
                characterLimit={800}
              />
              <StyledTextArea
                id="challengeDescription"
                placeholder={t('ResidentApp.ChallengeDescriptionInputPlaceholder')}
                rows={5}
                {...methods.register('challengeDescription', {
                  value: challenge?.description,
                  maxLength: {
                    value: 800,
                    message: t('InvalidLength', { ns: 'validation', maxLength: 800 }),
                  },
                  required: t('ChallengeDescriptionRequired', { ns: 'validation' }),
                })}
              />
              {methods.formState.errors.challengeDescription && (
                <ResidentAppFormErrorMessage
                  message={methods.formState.errors.challengeDescription.message}
                />
              )}
            </>
          ) : (
            <>
              <Label text={t('ResidentApp.ChallengeDescription')} marginBottom />
              <Text>{challenge?.description}</Text>
            </>
          )}
        </div>
      </Content>
      {methods && <GreyText>{t('NBText', {ns: 'residentAppTermsConditions'})}</GreyText>}
    </div>
  );
}

export default ChallengeInfoContent;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  max-width: 450px;
  margin-top: 25px;
`;

const DateInputs = styled.div`
  display: flex;
  gap: 10px;
`;

const StyledTextArea = styled(TextArea)`
  border: 1px solid ${p => p.theme.palette.forms.input.border};
  background-color: ${(p) => p.theme.action.filled};
`;

const ModalTitle = styled.h4`
  font-size: 16px;
  font-weight: 600;
  margin: 0;

  @media (min-width: 1024px){
    font-size: 18px;
  }
`;

const ModalDescription = styled.p`
  margin: 15px 0;
  font-size: 14px;

  @media (min-width: 1024px){
    font-size: 16px;
  }
`;

const List = styled.ul`
  padding-left: 25px;
`;

const ListItem = styled.li`
  white-space: pre-line;
  font-weight: 500;

  @media (min-width: 1024px){
    font-size: 16px;
  }

  & + li {
    margin-top: 5px;
  }
`;

const DateWrapper = styled.div`
  width: 50%;
`;

const StyledDatepicker = styled(DatePicker)`
  background-color: ${(p) => p.theme.action.filled};
  border: 1px solid ${p => p.theme.palette.forms.input.border};
  border-radius: 5px;
  box-shadow: 0px 5px 2px -4px ${(p) => p.theme.palette.forms.input.shadow};
  width: 100%;
`;

const AverageTemperatureInfo = styled.span`
  font-size: 12px;
  color: ${(p) => p.theme.palette.text.fair};
  display: block;
  margin-top: 10px;
`;

const Text = styled.p`
  margin: 0;
  font-size: 14px;
  white-space: pre-line;
  font-weight: 500;
`;

const GreyText = styled(Text)`
  color: ${(p) => p.theme.palette.text.fair};
  margin-top: 25px;
`;