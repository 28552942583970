import BuildingsGetBySiteIdQuery from '@settings/api/queries/Buildings/BuildingsGetBySiteIdQuery';
import AssetList from '@settings/components/molecules/AssetList/AssetList';
import { Asset } from '@shared/api/models/Asset/Asset';
import { Site } from '@shared/api/models/Site/Site';
import { useApiState } from '@shared/hooks/useApiState';
import { useTranslation } from 'react-i18next';

interface ISiteBuildingList {
  site: Site
}

const SiteBuildingList = ({ site }: ISiteBuildingList) => {
  const { t } = useTranslation();
  const { transform: buildings, loading } = useApiState({
    query: new BuildingsGetBySiteIdQuery(site.id),
    transformFunc: (buildings) => buildings.map(x => new Asset(x.id, x.name, x.address, x.buildingImageId, x.siteId)),
    initialTransformState: []
  }, []);

  return (
    <AssetList
      items={buildings}
      loading={loading}
      assetTypeName={t('Buildings', { ns: 'common' })}
      createlinkPath={assetId => `/site/${site.id}/building/${assetId}/settings`}
      showCreateButton
    />
  );
}

export default SiteBuildingList;