import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import { OperatorPrizeDto } from '@shared/api/models/ResidentApp/OperatorPrizeDto';
import { Title } from '@shared/components/atoms/Title/Title';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { format } from 'date-fns';
import { ReactNode, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { getOrdinalNumber } from '@dashboard/components/molecules/ResidentApp/ResidentAppUtils';
import { Link } from 'react-router-dom';
import { Button } from '@shared/components/atoms/Button/Button';

type ClauseItem = {
  text: ReactNode;
  children?: ClauseItem[];
  nestedLevel?: number;
}

type TermsAndConditionsProps = {
  title: string;
  prizes: OperatorPrizeDto[];
  openingDate: string;
  closingDate: string;
  targetValue: number;
}

const TermsAndConditions = ({title, prizes, openingDate, closingDate, targetValue}: TermsAndConditionsProps) => {
  const { t } = useTranslation('residentAppTermsConditions');
  const theme = useTheme();
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const { toLocale, getUnit } = useLocalisation();
  const prizeNames: ClauseItem[] = prizes.map((prize, index) => ({
    text: `${
      index === 0
        ? t('ResidentApp.MainPrize', {ns: 'molecules'})
        : t('ResidentApp.OrdinalNumberPrize', {
          ns: 'molecules',
          ordinalNumber: getOrdinalNumber(index + 1),
        })
    }: ${prize.prizeName}`,
  }));

  const clauses: ClauseItem[] = [
    {
      text: t('Clauses.1.Title'),
      children: [
        { text: t('Clauses.1.1') },
        { text: t('Clauses.1.2') },
        { text: t('Clauses.1.3') },
        { text: t('Clauses.1.4') },
        { text: t('Clauses.1.5') },
      ],
    },
    {
      text: t('Clauses.2.Title'),
      children: [
        {
          text: t('Clauses.2.1', {
            openingDate: format(new Date(openingDate), 'd MMMM yyyy'),
            closingDate: format(new Date(closingDate), 'd MMMM yyyy'),
          }),
        },
      ],
    },
    {
      text: t('Clauses.3.Title'),
      children: [
        {
          text: t('Clauses.3.1', {
            targetValue: `${toLocale(MetricType.Temperature, targetValue, { round: 2 })}${getUnit(
              MetricType.Temperature
            )}`,
          }),
        },
        { text: t('Clauses.3.2') },
        { text: t('Clauses.3.3'), children: prizeNames },
        { text: t('Clauses.3.4') },
        { text: t('Clauses.3.5') },
        { text: t('Clauses.3.6') },
        { text: t('Clauses.3.7') },
        { text: t('Clauses.3.8') },
        { text: t('Clauses.3.9') },
        { text: t('Clauses.3.10') },
        { text: t('Clauses.3.11') },
        { text: t('Clauses.3.12') },
      ],
    },
    {
      text: t('Clauses.4.Title'),
      children: [
        { text: t('Clauses.4.1') },
        { text: t('Clauses.4.2') },
        { text: t('Clauses.4.3') },
      ],
    },
    {
      text: t('Clauses.5.Title'),
      children: [
        {
          text: (
            <Trans i18nKey="residentAppTermsConditions:Clauses.5.1">
              <Link
                style={{ textDecoration: 'underline' }}
                to="https://www.utopi-portal.com/mobile-app-privacy-policy-resident-app"
                target="_blank"
                rel="noopener noreferrer"
              />
            </Trans>
          ),
        },
        { text: t('Clauses.5.2') },
      ],
    },
    { text: t('Clauses.6.Title'), children: [{ text: t('Clauses.6.1') }] },
  ];

  const ListItem = ({text, children, nestedLevel}: ClauseItem) => {
    return (
      <StyledListItem nestedLevel={nestedLevel}>
        <ListTitle nestedLevel={nestedLevel}>{text}</ListTitle>
        {children && (
          <List>
            {children.map((child, index) => (
              <ListItem key={index} text={child.text} children={child.children} nestedLevel={nestedLevel === undefined ? undefined : nestedLevel + 1}/>
            ))}
          </List>
        )}
      </StyledListItem>
    );
  };
  
  return (
    <div>
      <Title text={t('TermsAndConditions')} size="lg" />
      <Description>{t('TermsAndConditionsDescription')}</Description>
      <Accordion>
        <Header onClick={() => setIsAccordionOpen(!isAccordionOpen)}>
          <Text>{t('TermsAndConditions')}</Text>
          <FontAwesomeIcon icon={isAccordionOpen ? faChevronUp : faChevronDown} />
        </Header>
        <Content show={isAccordionOpen}>
          <TextContainer>
            <TermsConditionsTitle>
              '{title}' ("{t('PrizeDraw')}")
            </TermsConditionsTitle>
            <TermsConditionsTitle>{t('TermsAndConditionsCapitalized')}</TermsConditionsTitle>
            <TermsConditionsText>{t('TermsAndConditionsIntro')}</TermsConditionsText>
            <List>
              {clauses.map((clause, index) => (
                <ListItem
                  key={index}
                  text={clause.text}
                  children={clause.children}
                  nestedLevel={0}
                />
              ))}
            </List>
          </TextContainer>
          <Button
            onClick={() => setIsAccordionOpen(false)}
            label={
              <Flex>
                {t('Close', { ns: 'common' })}
                <FontAwesomeIcon icon={faChevronUp} color={theme.palette.text.medium} />
              </Flex>
            }
            tertiary
            style={{ marginLeft: 'auto', marginBottom: '20px'}}
          />
        </Content>
      </Accordion>
    </div>
  );
};

export default TermsAndConditions;

const Description = styled.p`
  margin: 10px 0 0 0;
  font-size: 14px;
  color: ${(p) => p.theme.palette.text.fair};
`;

const Accordion = styled.div`
  margin-top: 20px;
  width: 100%;
  background-color: ${(p) => p.theme.action.filled};
  overflow: hidden;
`;

const Header = styled.button`
  cursor: pointer;
  width: 100%;
  background: none;
  border: none;
  padding: 10px;
  font-family: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 5px 2px -4px ${(p) => p.theme.palette.forms.input.shadow};
  border-radius: 4px;
`;

const Text = styled.p`
  font-size: 14px;
  margin: 0;
`;

const Content = styled.div<{show: boolean}>`
  max-height: ${(p) => p.show ? '5000px' : '0px'};
  transition: ${(p) => p.show ? 'max-height 1s ease-in' : 'max-height 0.3s ease-in-out'};
  padding: 0 10px;
  visibility: ${(p) => p.show ? 'visible' : 'hidden'};
`;

const TextContainer = styled.div`
  background-color: ${(p) => p.theme.background.container};
  padding: 15px 10px;
  border-radius: 4px;
  display: grid;
  gap: 20px;
  margin-bottom: 20px;
`;

const TermsConditionsTitle = styled.h4`
  font-size: 16px;
  font-weight: 600;
  margin: 0;
`;

const TermsConditionsText = styled.p`
  font-size: 14px;
  margin: 0;
  color: ${(p) => p.theme.text.secondary};
`;

const List = styled.ol`
  counter-reset: item;
  padding-left: 0;
  font-size: 14px;

  ol{
    counter-reset: item;
  }
`;

const StyledListItem = styled.li<{nestedLevel?: number}>`
  display: block;
  color: ${(p) => p.theme.text.secondary};
  position: relative;
  padding-left: ${(p) => p.nestedLevel && p.nestedLevel >= 2 ? 0 : '25px'};
  font-weight: ${(p) => p.nestedLevel && p.nestedLevel > 0 ? '400' : 'bold'};

  &:before {
    content: counters(item, ".") " ";
    counter-increment: item;
    position: absolute;
    left: ${(p) => p.nestedLevel ? `${p.nestedLevel * -25}px` : 0};
  }

  & + li{
    margin-top: 20px;
  }

  & > ol {
    margin-top: 20px;
  }
`;

const ListTitle = styled.span<{nestedLevel?: number}>`
  padding-left: ${(p) => p.nestedLevel === 0 ? '25px' : 0};
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;