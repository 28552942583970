import { MetricType } from '@shared/api/enums/MetricType/MetricType';
import CardWithSideBorder from '@dashboard/components/molecules/ResidentApp/Common/CardWithSideBorder';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import ThresholdEditableTable from './ThresholdEditableTable';
import { SiteThresholdDto } from '@shared/api/models/ResidentApp/SiteThresholdDto';
import { ThresholdSeason } from '@shared/api/models/ResidentApp/enums/ThresholdSeason';
import { useState } from 'react';
import { useLocalisation } from '@shared/contexts/LocalisationContext/LocalisationContext';
import { useApi } from '@shared/hooks/useApi';
import SiteThresholdsResidentAppUpdateCommand from '@shared/api/queries/ResidentApp/SiteThresholds/SiteThresholdsResidentAppUpdateCommand';
import { useSiteContext } from '@src/components/pages/SitePage/SiteProvider';
import { mapToSiteThresholdDtos } from '@dashboard/components/molecules/ResidentApp/ResidentAppUtils';
import ThresholdEditTitle from './ThresholdEditTitle';

export type SiteThresholdForm = {
  thresholds: {
    seasonOne: {id: number, targetValue: number};
    seasonTwo: {id: number, targetValue: number};
  }[];
};

type TemperatureThresholdFormProps = {
  siteThresholds: SiteThresholdDto[];
};

const TemperatureThresholdForm = ({siteThresholds}: TemperatureThresholdFormProps) => {
  const { t } = useTranslation('molecules');
  const { site, siteOperator, refreshSiteOperator } = useSiteContext();
  const { toLocale, getUnit, fromLocale } = useLocalisation();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const {execute, loading, error} = useApi();
  const thresholdSeasonOne = siteThresholds.find((threshold) => threshold.thresholdSeason === ThresholdSeason.SeasonOne);
  const thresholdSeasonTwo = siteThresholds.find((threshold) => threshold.thresholdSeason === ThresholdSeason.SeasonTwo);

  console.log(siteThresholds)

  const methods = useForm<SiteThresholdForm>({
    defaultValues: {
      thresholds: [
        {
          seasonOne: thresholdSeasonOne
            ? {
              id: thresholdSeasonOne.id,
              targetValue: thresholdSeasonOne.seasonTarget > 0 ? toLocale(MetricType.Temperature, thresholdSeasonOne.seasonTarget, {round: 1}) : 0,
            }
            : { id: 0, targetValue: 0 },
          seasonTwo: thresholdSeasonTwo
            ? {
              id: thresholdSeasonTwo.id,
              targetValue: thresholdSeasonTwo.seasonTarget > 0 ? toLocale(MetricType.Temperature, thresholdSeasonTwo.seasonTarget, {round: 1}) : 0,
            }
            : { id: 0, targetValue: 0 },
        },
      ],
    },
  });

  const thresholds = [
    {
      title: t('ResidentApp.TemperatureUnitPerBed', {unit: getUnit(MetricType.Temperature)}),
      seasonOne: thresholdSeasonOne && thresholdSeasonOne.seasonTarget > 0 ? toLocale(MetricType.Temperature, thresholdSeasonOne.seasonTarget, {round: 1}) : 0,
      seasonTwo: thresholdSeasonTwo && thresholdSeasonTwo.seasonTarget > 0 ? toLocale(MetricType.Temperature, thresholdSeasonTwo.seasonTarget, {round: 1}) : 0,
    }
  ];

  const handleUpdateThreshold = async (data: SiteThresholdForm) => {    
    if(!siteOperator){
      return;
    } 

    await execute({
      query: new SiteThresholdsResidentAppUpdateCommand(site.id, mapToSiteThresholdDtos(data, siteOperator, fromLocale)),
      successMessage: t('ThresholdsUpdated', { ns: 'status' }),
      errorMessage: t('ThresholdsUpdateError', { ns: 'status' }),
      pendingMessage: t('ThresholdsUpdating', { ns: 'status' }),
    });

    if(!error){
      // reset edit mode
      setIsEditMode(false);
      refreshSiteOperator();
    }
  }

  return (
    <CardWithSideBorder>
      <ThresholdEditTitle 
        metricType={MetricType.Temperature}
        descriptionText={t('ResidentApp.TemperatureEnterThresholdText')}
        isEditMode={isEditMode}
        setIsEditMode={(isEditMode: boolean) => setIsEditMode(isEditMode)}
      />
      <FormProvider {...methods}>
        <ThresholdEditableTable
          isEditMode={isEditMode}
          methods={methods}
          thresholds={thresholds}
          onCancel={() => setIsEditMode(false)}
          onSave={methods.handleSubmit(handleUpdateThreshold)}
          metricType={MetricType.Temperature}
          loading={loading}
        />
      </FormProvider>
    </CardWithSideBorder>
  );
}

export default TemperatureThresholdForm;